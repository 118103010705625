<template>
        <el-dialog title="Reservations" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Club' >
                     <el-select v-model='selectedObject.IdClub'  class='full-width'>        <el-option v-for='item in Info.clubs' :key="'clubs' + item.Id" :label='item.Name' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='User' >
                     <el-select v-model='selectedObject.IdUser'  class='full-width'>        <el-option v-for='item in Info.users' :key="'users' + item.Id" :label='item.FirstName' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Type' >
                     <el-select class='full-width' v-model='selectedObject.Type' ><el-option label='guest_list' value='guest_list'></el-option><el-option label='table' value='table'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Event Date' >
                    <el-date-picker  class='full-width' v-model='selectedObject.EventDate' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Request Date' >
                    <el-date-picker  class='full-width' v-model='selectedObject.RequestDate' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Description' >
                    <el-input  class='full-width' v-model='selectedObject.Description' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Price Information' >
                    <el-input  class='full-width' v-model='selectedObject.PriceInformation' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Group Size' >
                    <el-input-number class='full-width' v-model='selectedObject.GroupSize' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Ladies Count' >
                    <el-input-number class='full-width' v-model='selectedObject.LadiesCount' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Status' >
                     <el-select class='full-width' v-model='selectedObject.Status' ><el-option label='pending' value='pending'></el-option><el-option label='confirmed' value='confirmed'></el-option><el-option label='rejected' value='rejected'></el-option><el-option label='cancelled' value='cancelled'></el-option><el-option label='' value=''></el-option>
                     </el-select>
                </el-form-item>
            </el-col>            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Reservations_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    IdClub: '' , IdUser: '' , Type: '' , EventDate: '' , RequestDate: '' , Description: '' , PriceInformation: '' , GroupSize: '' , LadiesCount: '' , Status: '' ,                 },
                Info:{
                    clubs: [], users: [],
                },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    IdClub: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdUser: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Type: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    EventDate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    RequestDate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Description: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    PriceInformation: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    GroupSize: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    LadiesCount: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Status: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("reservations/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("reservations/get_info_for_dialog" );
                this.Info.clubs = response.clubs;this.Info.users = response.users;            
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("reservations/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>